export default {
  addSuccess: '新增成功',
  saveSuccess: '保存成功',
  selectResource: '選擇資源',
  themePla: '非必填，選擇系列',
  mainTitlePla: '請輸入產品名稱',
  subTitlePla: '非必填，請輸入副標題',
  ticket: '門券',
  teamTypeName: '單項委託',
  productDescPla: '僅內部人員可見，不在外網展示',
  TabKeysEnum: {
    base: '基本信息',
    pack: '資源設定',
    price: '成本設定',
    cost: '團期報價',
  },
  productCodeTips: '錄入後不可編輯',
  rules: {
    productCode: '請輸入產品碼',
    prefixTips: 'DIS-前綴為香港迪士尼樂園API直連專用，請勿使用',
    productNameLen: '產品名稱總長度不能超過70',
    mainTitle: '產品名稱主標題不能為空',
    singleEntrustType: '請選擇單項委託類型',
    chargeUnit: '請選擇單位',
    ownerId: '請選擇產品負責人',
    depCode: '請選擇負責人部門',
    destinationObj: '請選擇目的地',
    terminals: '請選擇終端',
    productPoiInfos: '請添加景點',
    vendorId: '請選擇供應商',
    ticketCollectionMethod: '請選擇取票方式',
    branch: '請選擇中旅分社',
    ticketExchangeFormat: '請選擇換票證格式',
    validityEnum: '請選擇有效期',
    containSeason: '請選擇場次',
    underwriteTicket: '請選擇包銷',
    cancelPolicy: '請選擇取消政策',
    maxOrderQuantity: '最多預訂不能小於最小預訂',
  },
  addResource: '新增資源',
  editResource: '編輯資源',
  resourcePack: {
    delTips: '確認刪除資源?',
    rules: {
      resourceCode: '請輸入資源碼',
      resourceName: '資源名稱總長度不能超過70',
    },
  },
}
